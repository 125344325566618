<template>
  <div class="container">
    <h1>Дробилка молотковая</h1>


         <p style="color: red">надо ли добавить дробилку молотквовую? видел на других сайтах ее..  думаю она лишняя, луше дезинтератер добавить (есть фото не большой устоновки измельчения и смешивание)..</p>

  </div>
</template>

<script>
  export default {

    components: {},

    mixins: [],

    props: {},

    data() {
      return {};
    },

    computed: {},

    watch: {},

    mounted() {
    },

    methods: {},
  };
</script>

<style scoped>

</style>